<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.target_registry_id') }}
      </div>
      <registry-select-tree
        :cache="true"
        type="registry"
        :value="{id: parseInt(extensions.target_registry_id) || null}"
        @change="onExtensionChangeWithAlias('target_registry_id', $event)"
      />
    </div>
    <div class="property" v-if="extensions.target_registry_id">
      <div class="label">
        {{ $t('process_editor.element_properties.service_task.collection_name') }}
      </div>
      <el-input
          size="mini"
          :value="extensions.collection_name"
          @input="$emit('change-extension', { name: `collection_name`, value: $event })"
      ></el-input>
      <div class="label">
        {{ $t('process_editor.element_properties.service_task.collection_return_type') }}
      </div>
      <el-select
        :value="extensions.collection_return_type || 'unique'"
        @change="$emit('change-extension', { name: `collection_return_type`, value: $event })"
        size="mini"
        :placeholder="$t('process_editor.element_properties.service_task.collection_return_type')"
      >
        <el-option
          v-for="item in Object.keys($t('process_editor.element_properties.service_task.collection_return_types'))"
          :key="item"
          :label="$t(`process_editor.element_properties.service_task.collection_return_types.${item}`)"
          :value="item">
        </el-option>
      </el-select>
      <div class="label">
        {{ $t('process_editor.element_properties.service_task.variables') }}
        <el-button type="text" @click="addVariable"><i class="el-icon-plus"></i></el-button>
      </div>
      <el-row v-for="(item, index) in variables" :key="index" style="padding-bottom: 10px">
        <el-col :span="24" style="display: flex;flex-direction: row;align-items: center;">
          {{ index + 1 }}.
          <el-input
            size="mini"
            v-model="item.alias"
            style="padding-left: 10px"
            :placeholder="$t('main.fields.name')"
          ></el-input>
          <el-button size="mini" type="text" @click="removeVariable(index)" style="color:red; padding: 0 5px"><i
            class="el-icon-remove"></i></el-button>
        </el-col>
        <el-col :span="24">
          <el-select
            v-model="item.type"
            clearable
            size="mini"
            :placeholder="$t('process_editor.element_properties.type')"
          >
            <el-option
              v-for="item in Object.keys($t('process_editor.element_properties.service_task.variables_types'))"
              :key="item"
              :label="$t(`process_editor.element_properties.service_task.variables_types.${item}`)"
              :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24">
          <registry-select-tree
            :cache="true"
            value-as="number"
            v-if="item.type === 'field'"
            type="field"
            :parent-id="extensions.target_registry_id"
            v-model="item.value"
          ></registry-select-tree>
          <logic-tree-elements
            v-else-if="item.type === 'state'"
            v-model="item.value"
            :placeholder="$t(`process_editor.element_properties.service_task.variables_types.state`)"
            :object-id="parseInt(extensions.target_registry_id) || null"
            element-type="state">
          </logic-tree-elements>
          <el-input v-else size="mini" v-model="item.value" :placeholder="$t('main.fields.value')"></el-input>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import mixins from '../../mixins'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

export default {
  name: 'CalculateCollection',
  components: { RegistrySelectTree },
  mixins: [mixins],
  data () {
    return {
      variables: []
    }
  },
  mounted () {
    this.parseVariables()
  },
  watch: {
    'extensions.target_registry_id': {
      handler () {
        this.variables = []
      }
    },
    variables: {
      handler (value) {
        this.$emit('remove-extension', { filter: (_) => _.indexOf('variables.') === 0 })
        value.forEach((variable) => {
          if (variable.alias) {
            this.$emit('change-extension', { name: `variables.${variable.alias}.type`, value: variable.type })
            this.$emit('change-extension', { name: `variables.${variable.alias}.value`, value: variable.value })
          }
        })
      },
      deep: true
    }
  },
  methods: {
    parseVariables () {
      let extensions = Object.keys(this.extensions).filter(_ => _.indexOf('variables.') === 0)
      extensions.forEach((item) => {
        let alias = (item.match(/variables.([\s\S]+?).type/i) || [])[1] || null
        if (alias) {
          this.variables.push({
            alias: alias,
            type: this.extensions[`variables.${alias}.type`],
            value: this.extensions[`variables.${alias}.value`]
          })
        }
      })
    },
    addVariable () {
      this.variables.push({
        alias: null,
        type: null,
        value: null
      })
    },
    removeVariable (index) {
      this.variables.splice(index, 1)
    }
  }
}
</script>
